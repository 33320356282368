const DEFAULT_CLOUDINARY_CLOUD_NAME = "sonix-solar-gmbh"

export function getCSRFToken() { return document.querySelector("meta[name=csrf-token]").content }

export function isEscapeKey(key) {
  switch (key) {
    case "Esc": // IE/Edge specific value
    case "Escape":
      return true
  }

  return false
}

export function isStringEmpty(str) {
  if (str === undefined || str === null) { return true }
  if(str.replace(/\s/g, '').length < 1) { return true }
  return false
}

export const addClassToAll = (nodeList, classes) => { nodeList.forEach(element => element.classList.add(classes)) }
export const removeClassFromAll = (nodeList, classes) => { nodeList.forEach(element => element.classList.remove(classes)) }

// We don't have a value in process.env.CLOUDINARY_CLOUD_NAME since we don't want to load our entire .env file and the proposed mechanism using an initializer to set custom variables doesn't work. So we simply set a global variable in the layout using a partial when we're in development and fall back to the default otherwise.
export const cloudinaryCloudName = () => {
  return import.meta.env.CLOUDINARY_CLOUD_NAME ||
    window.CLOUDINARY_CLOUD_NAME ||
    DEFAULT_CLOUDINARY_CLOUD_NAME
}
